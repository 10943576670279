import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography,TextField } from '@mui/material';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { DSZLoginForm, DSZUpdateForm } from '../sections/auth/login';
import { useSelector } from 'react-redux';
import withAccessTokenCheck from '../hoc/withAccessTokenCheck';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function DSZLoginPage() {
  const userInfo = useSelector((state) => state.user.userInfo);
  const dszUserName = userInfo && userInfo.dszUserName;
  return (
    <>
      <Helmet>
        <title> DSZ Login </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            {dszUserName? 
            <>
              <Typography variant="h4" gutterBottom>
                Update Your Dropshipzone Account
              </Typography>
              <Typography variant="body2" sx={{ mb: 5 }}>            
              </Typography>
              <DSZUpdateForm dszUserName={dszUserName} />
            </>: 
            <>
              <Typography variant="h4" gutterBottom>
                Setup Dropshipzone Supplier Account
              </Typography>

              <Typography variant="body2" sx={{ mb: 5 }}>
                Don’t have an account? {''}
                <Link variant="subtitle2">Get started</Link>
              </Typography>
              <DSZLoginForm />
            </>
          }
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
export default withAccessTokenCheck(DSZLoginPage);