import { Helmet } from 'react-helmet-async';
import { CSVLink } from 'react-csv';
// @mui
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import { Container, Typography,Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Logo from '../components/logo';
import Loader from '../components/loader/Loader';
// sections
import { useSelector,useDispatch } from 'react-redux';
import { useState,useEffect, useRef } from 'react';
import withAccessTokenCheck from '../hoc/withAccessTokenCheck';
import { setStatistics } from '../store/reducers/productMatchingSlice';
//services
import { fetchStatistics, fetchJsonFile } from '../services/statisticsService';
import {convertShopifyToDSZProducts} from '../services/newProductLaunchService';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ResultPanel = styled('div')(({ theme }) => ({
    backgroundColor: '#f8f9fa', // light gray background
    padding: theme.spacing(2),
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // subtle shadow
    marginTop: theme.spacing(2),
}));

// ----------------------------------------------------------------------

function extractHeadersFromProduct(product) {
    return Object.keys(product).map(key => ({
        label: key,
        key: key
    }));
}


function NewProductLaunchPage() {
  const statisticInfo= useSelector((state) => state.productMatching.statistics);
  const accessToken = useSelector((state) => state.user.accessToken);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [errorMessage, setErrorMessage] = useState("");
  const markdownRate = userInfo?.markDownOptions?.defaultRate || 0;
  const [isLoading, setIsLoading] = useState(true);
  const [newProductsToDownload, setNewProductsToDownload] = useState([]);
  const [errorProductsToDownload, setErrorProductsToDownload] = useState([]);
  const [conversionResults, setConversionResults] = useState({
    total: 0,
    valid: 0,
    error: 0
  });
  const [displayResults, setDisplayResults] = useState(false);

  const dispatch = useDispatch();
  const [validProductHeaders, setValidProductHeaders] = useState([]);
  const [errorProductHeaders, setErrorProductHeaders] = useState([]);

  const topOfPageRef = useRef(null);
  const bottomOfPageRef = useRef(null);

  const scrollToTop = () => {
    topOfPageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    bottomOfPageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchStatisticsData = async ()=>{
    const response = await fetchStatistics(accessToken);
    if (response.success) {
      //set the retrieval time to avoid repeated calls
      response.data.retrievalTime = new Date();
      dispatch(setStatistics(response.data));
    } else {
      // Handle the error response from the API
      setErrorMessage(response.error);
    }
    //stop loading either success or failed call; if call failed, display error message instead
    setIsLoading(false);
  }

  const prepareNewProductsData = async ()=>{
     if (markdownRate <=0 || markdownRate >=1){
        setErrorMessage("Invalid markdown rate detected. Please set up markdown rate in your user settings.");
        return; 
    }
     const response = await fetchJsonFile(statisticInfo.productUnmatchedResultJson,"Failed to fetch unmatched products. Please try again.");
     if (response.success) {
        const results = convertShopifyToDSZProducts(response.data,markdownRate);
        setNewProductsToDownload(results.validProducts);
        setErrorProductsToDownload(results.errorProducts);
        const vHeaders = results.validProducts.length > 0 
        ? extractHeadersFromProduct(results.validProducts[0]) 
        : [];
        const eHeaders = results.errorProducts.length > 0?
        extractHeadersFromProduct(results.errorProducts[0]):[];
        
        setValidProductHeaders(vHeaders);
        setErrorProductHeaders(eHeaders);

        setConversionResults({
            total: response.data.length,
            valid: results.validProducts.length,
            error: results.errorProducts.length
          });
        setDisplayResults(true);
        scrollToBottom();
      } else {
        // Handle the error response from the API
        setErrorMessage(response.error);
        setDisplayResults(false);
        scrollToTop();
      }
    }

    const errorMessageLabel = (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
            <ErrorIcon sx={{ marginRight: 1 }} />
            <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>{errorMessage}</Typography>
          </Box>
        </Box>
      )

  useEffect(()=>{
    const THRESHOLD_IN_MINUTES = 10;
    const MILLISECONDS_IN_A_MINUTE = 1000 * 60;

    if (statisticInfo.retrievalTime && statisticInfo.retrievalTime instanceof Date) {
        const now = new Date();
        const differenceInMilliseconds = now - statisticInfo.retrievalTime;
        const differenceInMinutes = differenceInMilliseconds / MILLISECONDS_IN_A_MINUTE;
        if (differenceInMinutes > THRESHOLD_IN_MINUTES) {         
            fetchStatisticsData();
        } else {
            setIsLoading(false);
        }
    } else {
        fetchStatisticsData();
    }  
  },[])
  if (isLoading){
    return <Loader />
  }

  return (
    <>
      <div ref={topOfPageRef}></div>
      <Helmet>
        <title>New Product Launch | DSZ Sync App </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <>
            {errorMessage && errorMessageLabel}
            <>
              <Typography variant="h4" gutterBottom>
                New Product Launch
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', background: '#f4f6f8', padding: '10px', borderRadius: '4px' }}>
              Click 'Generate Unlisted Products CSV' to get two CSVs: 'newProducts.csv' for products processed without errors and 'errorProducts.csv' for those with data issues which requires your reviews. Before importing 'newProducts.csv' to Dropshipzone, you must manually add category data

              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', background: '#f4f6f8', padding: '10px', borderRadius: '4px' }}>
                The product price in this CSV is calculated based on the markdown rate set in your user settings. This means the Shopify price will be reduced by the specified markdown rate.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', background: '#f4f6f8', padding: '10px', borderRadius: '4px' }}>
                <b>Note:</b> The category column in the CSV will remain empty, as we currently do not have a direct way to set up category mapping.
                Once you have downloaded the CSV, you can manually add the category information in the CSV file and upload it to Dropshipzone's supplier portal.
              </Typography>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={prepareNewProductsData}>
                  Generate Unlisted Products CSV
              </LoadingButton>
              {displayResults &&
               <ResultPanel>
               <Typography variant="h4">Conversion Results</Typography>
               <Typography variant="body1">Total Products: {conversionResults.total}</Typography>
               <Typography variant="body1">Successful Products: {conversionResults.valid}</Typography>
               {conversionResults.valid > 0 && (
                 <CSVLink data={newProductsToDownload} headers={validProductHeaders} filename={"newProducts.csv"} enclosingCharacter={`"`}>Download Valid Products CSV</CSVLink>
               )}
               <Typography variant="body1">Error Products: {conversionResults.error}</Typography>
               {conversionResults.error > 0 && (
                 <CSVLink data={errorProductsToDownload} headers={errorProductHeaders} filename={"errorProducts.csv"}>Download Error Products CSV</CSVLink>
               )}
              </ResultPanel>             
              } 
              </>
            </>
          </StyledContent>
        </Container>
      </StyledRoot>
      <div ref={bottomOfPageRef}></div>
    </>
    );

}
export default withAccessTokenCheck(NewProductLaunchPage);