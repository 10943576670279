import { floorToTwoDecimalPoints } from "../utils/formatNumber";
import { extractImageURLs,convertWeightToKg,extractProductIdLastDigits } from "../utils/shopifyUtils";
import {parseToMoney} from "../utils/stringParser";
import { decodeHtml } from "../utils/stringHtmlDecoder";

export const convertShopifyToDSZProducts = (products, markdownRate) => {
    const validProducts = [];
    const errorProducts = [];
    const skusDicForLaunch = {};

    products.forEach(item => {
        try {
            const product = convertSingleProduct(item, markdownRate);
            if (product.Images === '') {
                errorProducts.push({ skuTitle: product["SKU Title"], sku: item.sku, error: 'Missing images' });
                return; // Exit this iteration early if images are missing
            }
            if(product["SKU"] === '') {
                errorProducts.push({ skuTitle: product["SKU Title"], sku: item.sku, error: 'Missing sku' });
                return; // Exit this iteration early if barcode is missing
            } 
            if (product["EAN Code"] === '') {
                errorProducts.push({ skuTitle: product["SKU Title"], sku: item.sku, error: 'Missing barcode' });
                return; // Exit this iteration early if barcode is missing
            }
            if (skusDicForLaunch[product["SKU"]]) {
                errorProducts.push({ skuTitle: product["SKU Title"], sku: item.sku, error: 'Duplicate sku' });
                return; // Exit this iteration early if sku is duplicate
            }
            if (product["Description"] === '') {
                errorProducts.push({ skuTitle: product["SKU Title"], sku: item.sku, error: 'Missing description' });
                return; // Exit this iteration early if sku is duplicate
            }


            skusDicForLaunch[product["SKU"]] = true;  
            // If no errors, add to validProducts
            validProducts.push(product);
    
        } catch (error) {
            errorProducts.push({ skuTitle: item.variantOptions? item.variantTitle: item.productTitle, sku: item.sku, error: error.message });
        }
    });
    

    return {
        validProducts,
        errorProducts
    };
    function convertSingleProduct(item,markdownRate) {
        const descriptionHtml = item.descriptionHtml? decodeHtml(item.descriptionHtml).replace(/"/g, '""'): "";
        const product = {
            "SKU": item.sku,
            "SKU Title": item.variantOptions? item.variantTitle: item.productTitle,
            //now leave the variant settings empty first
            "Parent SKU": "",
            "Parent SKU Title ": "",
            "Option1 Name": "",
            "Option1 Value": "",
            "Option2 Name": "",
            "Option2 Value": "",
            "EAN Code": item.barcode || "",
            "Description": descriptionHtml,
            "Status": 1,
            "Brand Name": "",
            "Colour": "",
            "Package Weight": "",
            "Package Length": "",
            "Package Width": "",
            "Package Height": "",
            "Package CBM(m3)": "",
            //to parse item price and work with markdown rate
            "Price (Incl. GST)": 0,
            "RRP (Incl. GST)": 0,
            "Stock": item.inventoryQuantity,
            "Categories": "",
            "Images":extractImageURLs(item.images),
            "Zone Rates ACT":0,
            "Zone Rates NSW_M":0,
            "Zone Rates NSW_R":0,
            "Zone Rates NT_M":0,
            "Zone Rates NT_R":0,
            "Zone Rates QLD_M":0,
            "Zone Rates QLD_R":0,
            "Zone Rates REMOTE":0,
            "Zone Rates SA_M":0,
            "Zone Rates SA_R":0,
            "Zone Rates TAS_M":0,
            "Zone Rates TAS_R":0,
            "Zone Rates VIC_M":0,
            "Zone Rates VIC_R":0,
            "Zone Rates WA_M":0,
            "Zone Rates WA_R":0
        };

        try {
            product["Price (Incl. GST)"] = floorToTwoDecimalPoints(parseToMoney(item.price) * (1 - markdownRate));
            product["RRP (Incl. GST)"] = floorToTwoDecimalPoints(item.compareAtPrice && item.compareAtPrice.length > 0 ? parseToMoney(item.compareAtPrice) : parseToMoney(item.price));
        } catch (error) {
            throw new Error(`Price error for SKU ${item.sku}: ${error.message}`);
        }



        // Handle variant options, assuming the array is sorted in the order of importance
        if (item.variantOptions && item.variantOptions.length > 0) {
            product["Parent SKU"] =  extractProductIdLastDigits(item.productId);
            product["Parent SKU Title "] = item.productTitle;
            product["Option1 Name"] = item.variantOptions[0].name || "";
            product["Option1 Value"] = item.variantOptions[0].value || "";
        if (item.variantOptions.length > 1) {
            product["Option2 Name"] = item.variantOptions[1].name || "";
            product["Option2 Value"] = item.variantOptions[1].value || "";
            }
        }

        const weightValue = parseFloat(item.weight);
        const weightUnit = item.weightUnit || "kg"; // Default to kg if unit is not provided
        const weightInKg = (!isNaN(weightValue) && weightValue > 0) 
                       ? convertWeightToKg(weightValue, weightUnit) 
                       : "";

        product["Package Weight"] = weightInKg;

        return product;
    }
}