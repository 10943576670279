import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button,CircularProgress,Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
//utils
import { generateMessage, pickShopName, getParams } from '../utils/shopifyUtils';
import axios from 'axios';

// components
import Logo from '../components/logo';

import { useSelector,useDispatch } from 'react-redux';
import { clearUserInfo, setAccessToken } from '../store/reducers/userSlice';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function CallBackPage() {
  const accessToken = useSelector((state) => state.user.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = getParams();
    const requiredParams = ['code', 'hmac','host','shop', 'state','timestamp'];

    if (!requiredParams.every(param => param in params)) {
      setError('Missing required params.');
    } else {
      const message = generateMessage(params);
      console.log('callback:'+ message);
      const apiParams = { shopName: pickShopName(params.shop), hmac: params.hmac, message, authCode: params.code,shopOrigin: params.shop,stateNonce: params.state };
      dispatch(clearUserInfo());
      axios.post(`${process.env.SUPPLIER_SYNC_API_URL}/shopifyAuth/complete`, apiParams)
      .then(response => {
        if (response.status === 200 && response.data && response.data.accessToken) {
          setIsLoading(false);
          dispatch(setAccessToken(response.data.accessToken));
          navigate('/dashboard');
        } else {
          setError('Unexpected response from server.');
        }
      })
      .catch(error => {
        setError(error.message);
        //test token generation
        // dispatch(setAccessToken("testtoken"));
      })
    } 

  }, []);
  
  
  const bodyComp = error? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
        <ErrorIcon sx={{ marginRight: 1 }} />
        <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>{error}</Typography>
      </Box>
      <Typography variant="body1" align="center" sx={{ mb: 2 }}>
        To install the app, please go back to your Shopify store and click on the app again to start the installation process.
      </Typography>
      <Button fullWidth variant="contained" color="primary" href="https://www.shopify.com/" target="_blank">
        Go to Shopify store
      </Button>
    </Box>
  ):(
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
      {isLoading? (<><CircularProgress sx={{ marginBottom: 2,color: '#00BFFF' }} />
      <Typography variant="body1" sx={{ color: '#00BFFF', fontWeight: 'bold', letterSpacing: '2px', marginTop: '16px' }}>LOADING...</Typography></>):(<>Access token loaded {accessToken}</>)}
    </Box>
  )

  return (
    <>
      <Helmet>
        <title> Callback | DSZ Sync App </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            {bodyComp}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
