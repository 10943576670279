export const loadUserState = () => {
    try {
      const serializedState = localStorage.getItem('suppliersync-user-state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };
  
  export const saveUserState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('suppliersync-user-state', serializedState);
    } catch (err) {
      console.error('Error saving user state to local storage:', err);
    }
  };
  