import {floorToTwoDecimalPoints} from '../utils/formatNumber';
import { extractImageURLs } from '../utils/shopifyUtils';
import { decodeHtml } from '../utils/stringHtmlDecoder';

export function comparePricesAndGenerateProductList(products, markdownRate) {
    const priceDiscrepancies = [];
  
    products.forEach(product => {
      const dszCost = parseFloat(product.dszCost);
      const shopifyPrice = parseFloat(product.shopifyPrice);
      const dszPrice = parseFloat(product.dszPrice);
      let shopifyCompareAtPrice = parseFloat(product.shopifyCompareAtPrice);
  
      if (isNaN(shopifyCompareAtPrice)) {
        shopifyCompareAtPrice = shopifyPrice; // fallback to shopifyPrice if shopifyCompareAtPrice is not available
      }
  
      const shopifyPriceAfterMarkdown = floorToTwoDecimalPoints(shopifyPrice * (1 - markdownRate));
      const shopifyCompareAtPriceFloorDown = floorToTwoDecimalPoints(shopifyCompareAtPrice);
      if (shopifyPriceAfterMarkdown !== dszCost || shopifyCompareAtPriceFloorDown !== dszPrice ) {
        priceDiscrepancies.push({
          "SKU": product.shopifySku,
          "New Price (Incl. GST)": shopifyPriceAfterMarkdown !== dszCost ? shopifyPriceAfterMarkdown: "",
          "New RRP (Incl. GST)": shopifyCompareAtPriceFloorDown !== dszPrice? shopifyCompareAtPriceFloorDown: "",
          "Zone Rates ACT":"",
          "Zone Rates NSW_M":"",
          "Zone Rates NSW_R":"",
          "Zone Rates NT_M":"",
          "Zone Rates NT_R":"",
          "Zone Rates QLD_M":"",
          "Zone Rates QLD_R":"",
          "Zone Rates REMOTE":"",
          "Zone Rates SA_M":"",
          "Zone Rates SA_R":"",
          "Zone Rates TAS_M":"",
          "Zone Rates TAS_R":"",
          "Zone Rates VIC_M":"",
          "Zone Rates VIC_R":"",
          "Zone Rates WA_M":"",
          "Zone Rates WA_R":""
        });
      }
    });
    return priceDiscrepancies;
};

export function compareContentsAndGenerateProductList(products){
    const contentDiscrepancies = [];
    products.forEach(product =>{
      let contentDiscrepancy = {};
      const shopifyProductTitle = product.shopifyVariantOptions? product.shopifyVariantTitle: product.shopifyProductTitle;
      const shopifyImages = extractImageURLs(product.shopifyImages);
      const shopifyDescriptionHtml = decodeHtml(product.shopifyDescriptionHtml);
      const dszDescriptionHtml = decodeHtml(product.dszDescription);
      if (product.shopifySku !== product.dszSku){
        contentDiscrepancy["SKU"] = product.shopifySku;
      }
      if (shopifyProductTitle !== product.dszTitle){
        contentDiscrepancy["SKU Title"] = shopifyProductTitle;
      }
      //Waiting for Backend to support dsz variant list value return
      // if (product.shopifyVariantOptions && product.shopifyVariantOptions.length > 0) {
      //   const shopifyParentSkuId =  extractProductIdLastDigits(product.shopifyProductId);
      //   const shopifyParentSkuTitle = product.shopifyProductTitle;
      //   const shopifyOption1Name = product.shopifyVariantOptions[0].name || "";
      //   const shopifyOption1Value = product.shopifyVariantOptions[0].value || "";
      //   if(shopifyParentSkuId)
      //   if(product.shopifyVariantOptions.length > 1){
      //     const shopifyOption2Name = product.shopifyVariantOptions[1].name || "";
      //     const shopifyOption2Value = product.shopifyVariantOptions[1].value || "";
        
      //   }
      // }
      if (product.shopifyBarcode !== product.dszEanCode){
        contentDiscrepancy["EAN Code"] = product.shopifyBarcode;
      }
      if (shopifyDescriptionHtml !== dszDescriptionHtml){
        contentDiscrepancy["Description"] = shopifyDescriptionHtml;
      }
      if (product.shopifyInventoryQuantity !== product.dszStockQty){
        contentDiscrepancy["Stock"] = product.shopifyInventoryQuantity;
      }
      if(shopifyImages!== product.dszFirstImage){
        contentDiscrepancy["Images"] = shopifyImages;
      }

      if (Object.keys(contentDiscrepancy).length > 1){
        //the SKU key is always required for other fields updates
        contentDiscrepancy["SKU"] = product.shopifySku;
        const productToUpdate = {
          "SKU": product.shopifySku,
          "SKU Title": contentDiscrepancy["SKU Title"]? contentDiscrepancy["SKU Title"]: "",
          //TODO: wait for backend to support variant list on existing products
          "Parent SKU": "",
          "Parent SKU Title ": "",
          "Option1 Name": "",
          "Option1 Value": "",
          "Option2 Name": "",
          "Option2 Value": "",
          "EAN Code": contentDiscrepancy["EAN Code"]? contentDiscrepancy["EAN Code"]: "",
          "Description": contentDiscrepancy["Description"]? contentDiscrepancy["Description"]: "",
          "Status": "",
          "Brand Name": "",
          "Colour": "",
          "Package Weight": "",
          "Package Length": "",
          "Package Width": "",
          "Package Height": "",
          "Package CBM(m3)": "",
          "Stock": contentDiscrepancy["Stock"]? contentDiscrepancy["Stock"]: "",
          "Images": contentDiscrepancy["Images"]? contentDiscrepancy["Images"]: ""
        }
        contentDiscrepancies.push(productToUpdate);
      }
    });
    return contentDiscrepancies;
}
  