import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  userInfo: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearUserInfo:(state) => {
      state.userInfo = null;
    }
  },
});

export const { setAccessToken, clearAccessToken,setUserInfo,clearUserInfo } = userSlice.actions;
export default userSlice.reducer;
