import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import productMatchingReducer from './productMatchingSlice';

const rootReducer = combineReducers({
  user: userReducer,
  productMatching: productMatchingReducer
});

export default rootReducer;
