export function getParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }
    return params;
  }
  
export function generateMessage(params) {
    const messageParams = {};
    for (const [key, value] of Object.entries(params)) {
      if (key !== 'hmac') {
        messageParams[key] = value;
      }
    }
    const sortedKeys = Object.keys(messageParams).sort();
    const sortedMap = {};
    for (const key of sortedKeys) {
      sortedMap[key] = messageParams[key];
    }
    
    return Object.entries(sortedMap).map(([key, value]) => `${key}=${value}`).join('&');
  }
  
export function pickShopName(shop) {
    const dotIndex = shop.indexOf('.');
    if (dotIndex >= 0) {
      return shop.substring(0, dotIndex);
    } else {
      return shop;
    }
  }

export function extractImageURLs(images) {
    return images.map(image => removeQueryParams(image.src)).join(', ');
}

function removeQueryParams(url){
  const urlParts = url.split('?');
  return urlParts[0];
}

export function convertWeightToKg(weight, unit) {
  let weightInKg = 0;

  // Check if weight is present and a valid number.
  if (weight && !isNaN(parseFloat(weight))) {
      switch (unit.toLowerCase()) {
          case 'pounds':
          case 'lb':
          case 'lbs': // Including "lbs" in case it's used as well
              weightInKg = weight * 0.45359237; // convert pounds to kg
              break;
          case 'ounces':
          case 'oz':
              weightInKg = weight * 0.02834952; // convert ounces to kg
              break;
          case 'grams':
          case 'g':
              weightInKg = weight * 0.001; // convert grams to kg
              break;
          case 'kilograms':
          case 'kg':
              weightInKg = weight; // already in kg
              break;
          default:
              // If unit is unsupported, default weightInKg remains 0
              console.warn(`Unsupported weight unit: ${unit}, defaulting to 0 kg.`);
      }
  }

  return parseFloat(weightInKg.toFixed(2)); // format to 2 decimal places and convert back to float
}

export function extractProductIdLastDigits(productGid) {
  const parts = productGid.split('/');
  const lastElement = parts[parts.length - 1]; // gets the last element
  if (lastElement.length <= 5) {
      return lastElement;
  }
  return lastElement.slice(-5); // gets the last 5 characters/digits
}