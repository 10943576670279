import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import {saveUserState, loadUserState} from '../localStorage';
const persistedUserState = loadUserState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    user: persistedUserState,
  }
});

store.subscribe(()=>{
    saveUserState(store.getState().user);
})

export default store;
