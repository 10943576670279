import axios from 'axios';
import { handleUnauthorizedError } from '../utils/errorHandler';

export const fetchStatistics = async (accessToken) => {
    const workflowFetchUrl = `${process.env.SUPPLIER_SYNC_API_URL}/common/statistics`;

    try {
        const response = await axios.get(workflowFetchUrl, {
            headers: {
                'Authorization': accessToken
            }
        });

        if (response.status === 200 && response.data && response.data.statisticInfo) {
            let summaryData = response.data.statisticInfo;
            // Record current retrieval time
            summaryData.retrievalTime = new Date();
            return { success: true, data: summaryData };
        } else {
            return { success: false, error: "Failed to fetch summary data, please try again later." };
        }
    } catch (error) {
        //if 401, self redirect to login page
        handleUnauthorizedError(error);
        return { success: false, error: "Failed to fetch summary data, please try again later.", originalError: error };
    }
};

export const fetchJsonFile = async (jsonUrl, errorMessage) => {
    if (!jsonUrl || jsonUrl === '') {
        return { success: false, error: "The product matching initialization is in progress. You can check its status in dashboard and revisit this page once the product matching is completed." };
    }
    try {
        const response = await axios.get(jsonUrl);
        if (response.status === 200) {
            return {success: true, data: response.data};
        } else {
            return { success: false, error: errorMessage};
        }
    } catch (error) {
        return { success: false, error:errorMessage};
    }
};
