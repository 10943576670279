import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   statistics:{}
};

const productMatchingSlice = createSlice({
  name: 'productMatching',
  initialState,
  reducers: {
    setStatistics: (state, action) => {
      state.statistics = action.payload;
    }
  },
});

export const { setStatistics} = productMatchingSlice.actions;
export default productMatchingSlice.reducer;
