import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box,Link } from '@mui/material';
import Logo from '../components/logo';

const StyledRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper, // Set the background color for the entire page
    color: theme.palette.text.primary,
    minHeight: '100vh',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  }));
  
  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 1280,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4), // Updated padding for better spacing
    backgroundColor: '#f3f6f9', // Light background for the content area
    boxShadow: '0 3px 6px rgba(0,0,0,0.1)', // Soft shadow for depth
    borderRadius: theme.shape.borderRadius, // Optional: if you want rounded corners
    '& h3': {
      color: theme.palette.primary.main, // Primary color for main titles
    },
    '& h5': {
      color: theme.palette.secondary.main, // Secondary color for section titles
    },
    '& p': {
      marginBottom: theme.spacing(2), // Consistent bottom margin for paragraphs
    },
  }));
  
  const EmailLink = styled(Link)(({ theme }) => ({
    color: theme.palette.info.main, // Color for email links or you can use theme.palette.primary.main
  }));

function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | DSZ Sync App</title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Logo
              sx={{
                position: 'absolute',
                top: { xs: 16, sm: 24, md: 40 },
                left: { xs: 16, sm: 24, md: 40 },
              }}
            />
            <Typography variant="h3" gutterBottom>
              Privacy Policy
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                MVC Consulting Pty Ltd (ABN 41 658 330 717) and our related bodies corporate (collectively, "we," "us," and "our") partner with Dropshipzone and operate the Shopify Supplier Sync App for Dropshipzone. We are dedicated to protecting the privacy of our customers, website visitors, and other individuals with whom we interact. 
              </Typography>
              <Typography>
                We comply with the Australian Privacy Principles as set out in the Privacy Act 1988 (the "Privacy Act").
                This policy outlines how we collect, use, and disclose private data regarding:
                    (1) End customers' personal information from Dropshipzone retailers.
                    (2) Sync App behavior data.
              </Typography>
            </Box>
            
            <Typography variant="h5" gutterBottom>
                What is Personal Information?
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                "Personal information" is information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not, and whether it is recorded in material form or not. Personal information includes "sensitive information" as defined in the Privacy Act.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Types of Personal Information We Collect
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                We collect personal information about end customers such as contact details, including names, phone numbers, and addresses. This facilitates the development of the order sync from Dropshipzone to our suppliers' Shopify stores, enabling our suppliers to directly deliver ordered items to retailers' end customers.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Collection of End Customer Personal Information
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                We may collect personal information when:
              </Typography>
              <Typography variant="body1" paragraph>
                The order sync feature is developed from the Dropshipzone supplier portal to suppliers' Shopify stores and our users (suppliers) enable this feature in the app.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                If We Are Unable to Collect Personal Information            
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                Should we be unable to collect end customers' personal information, it may not be possible to provide the automated order sync feature, which facilitates the automatic receipt of orders in the Shopify stores for our suppliers.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Opting Out of Personal Information Collection           
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
              You may opt to disable the order sync feature to prevent the Sync App from collecting end customers' personal information. Other features such as inventory sync and new product import will remain available for use.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Security of Personal Information           
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                MVC Consulting Pty Ltd is committed to ensuring that your information is secure. We implement physical, electronic, and managerial procedures to help prevent unauthorized access or disclosure, to maintain data security, and to use the information we collect correctly. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Website Visit         
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                When you visit our websites, we may collect certain information like browser type, operating system, and the website you visited immediately before ours. This information is aggregated to analyze how our website is used and to improve our service.
              </Typography>
              <Typography variant="body1" paragraph>
                We may use cookies on our websites to identify you on return visits and to store your site preferences. Cookies are not malicious programs that can damage your computer. Most web browsers accept cookies by default, but you can modify your browser settings to decline cookies if you prefer, which may impact your experience with our site. Our website uses cookies to analyze traffic and enhance visitor experience, as well as to deliver targeted ads through services like Google AdWords.
              </Typography>
              <Typography variant="body1" paragraph>
                Our site may include links to other websites not owned or controlled by us. These are provided for convenience only and do not signify our endorsement. We are not responsible for the privacy practices of these other sites and encourage you to read their privacy statements. 
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Complaints about Privacy          
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                If you have any complaints regarding this policy or our handling of personal information, please contact us at <EmailLink href="mailto:admin@mvcconsulting.com.au">admin@mvcconsulting.com.au</EmailLink>. We take complaints seriously and will respond swiftly to resolve any issues.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Changes to This Privacy Policy       
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                We may update this Privacy Policy periodically at our discretion and in accordance with the law. Any changes will be effective immediately upon posting the updated policy on our website. We encourage you to review our Privacy Policy regularly.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
                Data Retention and Removal Strategy    
            </Typography>
            <Box sx={{ marginBottom: '24px' }}>
              <Typography variant="body1" paragraph>
                At MVC, your data privacy is a priority. We retain end customer data on our core platform for order synchronization. End customer data is removed one year after successful order fulfillment to account for any potential customer-initiated changes that may require data updates.
              </Typography>
            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default PrivacyPolicyPage;
