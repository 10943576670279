import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import DSZLoginPage from './pages/DSZLoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import InstallPage from './pages/InstallPage';
import CallBackPage from './pages/CallBackPage';
import SettingPage from './pages/SettingPage';
import NewProductLaunchPage from './pages/NewProductLaunchPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ExistingProductManagePage from './pages/ExistingProductManagePage';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'dszlogin', element: <DSZLoginPage />},
        { path: 'setting', element: <SettingPage />},
        { path: 'newproductlaunch',element: <NewProductLaunchPage />},
        { path: 'existingproductmanage',element: <ExistingProductManagePage />}
      ],
    },
    {
      path: 'privacypolicy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: 'dszlogin',
      element: <DSZLoginPage />,
    },
    {
      path:'auth',
      element: <InstallPage />,
    },
    {
      path:'callback',
      element: <CallBackPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
