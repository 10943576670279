import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import Logo from '../logo';
import { Container, Typography,CircularProgress,Box } from '@mui/material';
const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

export default function Loader(){
    return (
        <>
          <Helmet>
              <title> App loading | DSZ Sync App </title>
          </Helmet>
          <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
          <Container maxWidth="sm">
            <StyledContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
                  <CircularProgress sx={{ marginBottom: 2,color: '#00BFFF' }} />
                  <Typography variant="body1" sx={{ color: '#00BFFF', fontWeight: 'bold', letterSpacing: '2px', marginTop: '16px' }}>LOADING...</Typography>
              </Box>
            </StyledContent>
          </Container>
        </StyledRoot>
        </>)
}