import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button,Box,Card,Grid, TextField,InputAdornment,CircularProgress} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import axios from 'axios';

// components
import Logo from '../components/logo';
import ToggleSwitch from '../components/toggleSwitch/ToggleSwitch';

import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import withAccessTokenCheck from '../hoc/withAccessTokenCheck';

import { setUserInfo } from '../store/reducers/userSlice';

//service
import { updateSettings } from '../services/settingService';
import { set } from 'lodash';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

function SettingPage() {
  const [error, setError] = useState(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const accessToken = useSelector((state) => state.user.accessToken);
  const dispatch = useDispatch();
  //in component, display and take percentage value; while in redux store, it keeps the rate
  const [markdownValue, setMarkdownValue] = useState(userInfo?.markDownOptions?.defaultRate *100|| 0);
  const [submitted, setSubmitted] = useState(false);
  const [isMarkdownSubmitLoading, setIsMarkdownSubmitLoading] = useState(false);

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));
  

  const handleMarkDownChange = (event) => {
     if (submitted){
      setSubmitted(false);
     } 
     if (error){
      setError(null);
     }
     setMarkdownValue(event.target.value);
  }
  const handleSubmit = async () => {
    setIsMarkdownSubmitLoading(true);
    //handle markdown submit
    const response = await updateSettings(accessToken,userInfo,{markDownOptions:{defaultRate:markdownValue/100}});
    if (response.success){
      dispatch(setUserInfo(response.data))
      setSubmitted(true);
    } else {
      setError(response.error);
    }
    setIsMarkdownSubmitLoading(false);
  };

  const handleToggle = async (event, newValue) => {
    if (error){
      setError(null);
    }
    const response = await updateSettings(accessToken,userInfo,{"invSyncSetting":newValue});
    if (response.success){
      dispatch(setUserInfo(response.data))
    } else {
      setError(response.error);
    }
  }

  const bodyComp = (
     <Grid container spacing={2}>
        {error &&
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
              <ErrorIcon sx={{ marginRight: 1 }} />
              <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>{error}</Typography>
            </Box>
          </Box>   
        }
        <Card item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', border: '1px solid #2065D1', backgroundColor: 'transparent', padding: '5px', alignItems: 'center', minHeight: '100px', width:"100%", marginBottom: "2em" }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '70%', justifyContent: 'space-between'}}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Inventory Sync
            </Typography> 
            <ToggleSwitch enabled={userInfo.invSyncSetting === true} onToggle={handleToggle} />
          </Box>
          <Typography variant="body2" sx={{ width: '70%' }}>
            Enable Inventory Sync to automatically update inventory from Shopify to Dropshipzone
          </Typography> 
        </Card>
        <Card item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', border: '1px solid #2065D1', backgroundColor: 'transparent', padding: '5px', alignItems: 'center', minHeight: '150px',width:"100%" }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '70%', justifyContent: 'space-between'}}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Markdown Percentage
            </Typography>
            <TextField
              variant="outlined"
              value={markdownValue}
              onChange={(e) => handleMarkDownChange(e)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
            />
          </Box>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit} 
            sx={{ marginTop: '1em' }}
            disabled={isMarkdownSubmitLoading}
            startIcon={isMarkdownSubmitLoading ? <CircularProgress size="1rem" /> : null}
          >
            {isMarkdownSubmitLoading ? 'Submitting...' : 'Submit'}
          </Button>
          {submitted && (
            <Typography variant="body2" color="success" sx={{ marginTop: '1em' }}>
              Changes saved successfully!
            </Typography>
          )}
          <Typography variant="body2" sx={{ width: '70%', marginTop: '1em' }}>
            Set a markdown percentage to reduce product prices by the specified amount.
          </Typography>
        </Card>
     </Grid>
  )
 

  return (
    <>
      <Helmet>
        <title> Settings | DSZ Sync App </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            {bodyComp}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default withAccessTokenCheck(SettingPage);