import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid, Container, Typography,Box,Button, IconButton,Alert,CircularProgress } from '@mui/material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal } from '@mui/material';
import Loader from '../components/loader/Loader';
// components
import { Link } from 'react-router-dom';
import Timer from '../components/timer/Timer';
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';

import withAccessTokenCheck from '../hoc/withAccessTokenCheck';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { setStatistics } from '../store/reducers/productMatchingSlice';
import { useEffect, useState } from 'react';

//import utils
import mockFetchData from '../utils/mockFetchData';
import { handleUnauthorizedError } from '../utils/errorHandler';
import axios from 'axios';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ModalStyle =  {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------



function DashboardAppPage() {
  const [isInitlizationModalOpen, setIsInitlizationModalOpen] = useState(false);
  const [isTabFocused,setIsTabFocused] = useState(true);
  const [workflowList, setWorkflowList] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const userInfo = useSelector((state) => state.user.userInfo);
  const accessToken = useSelector((state) => state.user.accessToken);
  const handleClose = ()=> setIsInitlizationModalOpen(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);
  const dispatch = useDispatch();
  //add the component mount to fetch the workflow data
  //if workflow data is empty, display the initialize workflow modal popup

  const fetchWorkflows = async (isSuccess)=>{
    setIsWorkflowLoading(true);
    try {
      const workflowFetchUrl = `${process.env.SUPPLIER_SYNC_API_URL}/inventory/productMatching/status`
      const response = await axios.get(workflowFetchUrl,{
       headers: {
           'Authorization': accessToken
         }
      })
      //const workflowListData =isSuccess? data: []

      //const response = await mockFetchData(200,{workflowList:workflowListData});
      
      if(response.status === 200 && response.data){
         setErrorMessage("");
         if(response.data.resultList && response.data.resultList.length > 0){
           setWorkflowList(response.data.resultList);
          }else{
            setIsInitlizationModalOpen(true);
          }
      }else{
        setErrorMessage("Failed to fetch workflow data, please try again later.");
      }
    } catch(error){
      handleUnauthorizedError(error);
      setErrorMessage("Failed to fetch workflow data, please try again later.");
    } finally {
      setIsWorkflowLoading(false);
    }
  }

  const fetchSummaryData = async ()=>{
    try {
      const workflowFetchUrl = `${process.env.SUPPLIER_SYNC_API_URL}/common/statistics`
      const response = await axios.get(workflowFetchUrl,{
       headers: {
           'Authorization': accessToken
         }
      })
      if(response.status === 200 && response.data&& response.data.statisticInfo){
        let summaryData = response.data.statisticInfo;
        //record current retrieval time
        summaryData.retrievalTime = new Date();
        setSummaryData(summaryData);
        dispatch(setStatistics(summaryData));
        setIsLoading(false);

      }else{
        setErrorMessage("Failed to fetch summary data, please try again later.");
      }
    } catch(error){
      handleUnauthorizedError(error);
      setErrorMessage("Failed to fetch summary data, please try again later.");
    }
  }

  const handleInitialize = async ()=>{
    try {
      // initialize workflow
      await axios.post(`${process.env.SUPPLIER_SYNC_API_URL}/inventory/workflow/productMatching`,{},{ headers: {
        'Authorization': accessToken,
      }})
      fetchWorkflows(true);
      handleClose();
    } catch(error){
      if(error.response && error.response.status === 429){
        setErrorMessage("The workflow is currently running, please try trigger the new workflow later.");
      } else {
        handleUnauthorizedError(error);
        setErrorMessage("Failed to initialize the product matching workflow, please try again later.");
      }
    }
  }
   
  const refresh = async ()=>{
    fetchSummaryData();
    fetchWorkflows();
  }

  useEffect(()=>{
    if (!userInfo.dszUserName) {
      //if there is no dsz User Name, no need to fetch the summary data as binding is not ready yet;
      setIsLoading(false);
    } else {
      //fetch summary data
      fetchSummaryData();
      //fetch the workflow data, access token has been checked in withAccessTokenCheck HoC so we can use it directly
      fetchWorkflows();
    }

    // not render timer nor trigger auto-refreshment to avoid unnecessary API calls
    const handleVisibilityChange = () => {
      setIsTabFocused(document.visibilityState === 'visible');
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  },[])
  const workflowTableComponent = (
    isWorkflowLoading ? 
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
                  <CircularProgress sx={{ marginBottom: 2,color: '#00BFFF' }} />
                  <Typography variant="body1" sx={{ color: '#00BFFF', fontWeight: 'bold', letterSpacing: '2px', marginTop: '16px' }}>LOADING WORKFLOW LIST...</Typography>
    </Box>
    : 
    <TableContainer component={Paper}>
            <Table>
             <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Results</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Product Sync CSV</TableCell>
                  <TableCell>Inventory Sync CSV</TableCell>

                </TableRow>
             </TableHead>
             <TableBody>
                {workflowList.map((row,index) => (
                  <TableRow key={index}>
                    <TableCell>Product and Inventory Sync</TableCell>
                    <TableCell>{row.workFlowStatus}</TableCell>
                    <TableCell>{row.createdTime}</TableCell>
                    <TableCell>{row.finishTime}</TableCell>
                    <TableCell>{row.workFlowResult}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>{row.matchingResultCsvUri? (<a href={row.matchingResultCsvUri} download="result.csv">Download</a>):"NA"}</TableCell>
                    <TableCell>{row.invSyncResultCsvUri?(<a href={row.invSyncResultCsvUri} download="result.csv">Download</a>):"NA"}</TableCell>
                  </TableRow>
                ))}
             </TableBody>
            </Table>
          </TableContainer>
  );
  const errorMessageLabel = (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
        <ErrorIcon sx={{ marginRight: 1 }} />
        <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>{errorMessage}</Typography>
      </Box>
    </Box>
  )

  const isWorkFlowRunning = workflowList && workflowList.length > 0 && !workflowList[0].finishTime;
 
  if(isLoading){
    return <Loader />
  }
  
  
  if(!userInfo.dszUserName){
     return (
      <>
       <Helmet>
        <title> Dashboard </title>
       </Helmet>
       <Container maxWidth="sm">
          <StyledContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
            <ErrorIcon sx={{ marginRight: 1 }} />
            <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>Action Required</Typography>
          </Box>
          <Typography variant="body1" align="center" sx={{ mb: 2 }}>
            To fully utilize this app, please link your Dropshipzone account first. Click the button below to navigate to the Dropshipzone account settings page.
          </Typography>
          <Button fullWidth variant="contained" color="primary" component={Link} to="/dszlogin">
            Bind Dropshipzone Account
          </Button>
        </Box>
        </StyledContent>
        </Container>
      </>
     )
  }

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        {!userInfo.invSyncSetting && <Alert severity="warning" sx={{ marginBottom: 2 }}>
            It appears you haven't enabled the 'Inventory Sync' feature yet. You can begin by clicking the 'Manual Product Matching' button to establish links between your Shopify and Dropshipzone products. This will display a comprehensive list of all matched products along with their corresponding inventory values across both platforms.
            <br />
            After verifying that the inventory values align with your expectations, proceed to the <Link to="/dashboard/setting">settings page</Link> to enable the 'Inventory Sync' feature. This will allow for automatic syncing of inventory numbers from Shopify to Dropshipzone.
        </Alert>}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <a style={{textDecoration:'none'}} href={summaryData.productMatchedResultCsv} download="result.csv">
            <AppWidgetSummary title="Synchronized Products" total={summaryData.productMatchedNumber?summaryData.productMatchedNumber: "N/A"} icon={'fa:product-hunt'} />
            </a>         
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Inventory Changes" total={summaryData.inventorySyncedNumber?summaryData.inventorySyncedNumber:"N/A" } color="info" icon={'ic:round-inventory-2'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Dropshipzone Orders" total={summaryData.orderSyncedNumber?summaryData.orderSyncedNumber: "N/A"} color="info" icon={'material-symbols:order-approve-sharp'} />
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {!userInfo.invSyncSetting && (
                <Button variant="contained" disabled ={isWorkFlowRunning || isWorkflowLoading} onClick={handleInitialize}>
                   {isWorkFlowRunning ?"Product Matching Processing":"Manual Product Matching" } 
                </Button>
              )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isTabFocused && <Timer onTimeout={refresh} />}
              <IconButton onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {errorMessage && errorMessageLabel}
            {workflowTableComponent}
        </Grid>
        <Modal
          open={isInitlizationModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={ModalStyle}>
            <h2 id="modal-title">Initialize the product matching</h2>
            <p id="modal-description">
               This is the first time you use the product matching feature, please click the button below to initialize the product matching.
            </p>
            <Button onClick={handleInitialize}>Initialize</Button>
          </Box>
      </Modal>

        </Grid>
      </Container>

    </>
  );
}

export default withAccessTokenCheck(DashboardAppPage);