import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button,CircularProgress,Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import axios from 'axios';
//utils
import { generateMessage, pickShopName, getParams } from '../utils/shopifyUtils';

// components
import Logo from '../components/logo';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import withAccessTokenCheck from '../hoc/withAccessTokenCheck';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function InstallPage() {
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);


  useEffect(() => {
    // if(accessToken){
    //   navigate('/dashboard')
    // }
    const params = getParams();
    const requiredParams = ['shop', 'hmac', 'timestamp'];

    if (!requiredParams.every(param => param in params)) {
      setError('Missing required params.');
    } else {
      const message = generateMessage(params);
      console.log(message);
      const apiParams = { shopName: pickShopName(params.shop), hmac: params.hmac, message };
      axios.post(`${process.env.SUPPLIER_SYNC_API_URL}/shopifyAuth/initialise`, apiParams)
      .then(response => {
        if (response.status === 200 && response.data && response.data.redirectUrl) {
          setRedirectUrl(response.data.redirectUrl);
        } else {
          setError('Unexpected response from server.');
        }
      })
      .catch(error => {
        setError(error.message);
      })
    } 

  }, []);
  
  
  const bodyComp = error? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
        <ErrorIcon sx={{ marginRight: 1 }} />
        <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>{error}</Typography>
      </Box>
      <Typography variant="body1" align="center" sx={{ mb: 2 }}>
        To install the app, please go back to your Shopify store and click on the app again to start the installation process.
      </Typography>
      <Button fullWidth variant="contained" color="primary" href="https://www.shopify.com/" target="_blank">
        Go to Shopify store
      </Button>
    </Box>
  ):(
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
      <CircularProgress sx={{ marginBottom: 2,color: '#00BFFF' }} />
      <Typography variant="body1" sx={{ color: '#00BFFF', fontWeight: 'bold', letterSpacing: '2px', marginTop: '16px' }}>LOADING...</Typography>
    </Box>
  )
  if (redirectUrl){
    if (!redirectUrl.startsWith('https://')) {
      redirectUrl = 'https://' + redirectUrl;
    }
    window.location.href= redirectUrl;
    // return null;
  }

  return (
    <>
      <Helmet>
        <title> Install | DSZ Sync App </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            {bodyComp}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default withAccessTokenCheck(InstallPage);