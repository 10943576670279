import React from 'react';
import Switch from '@mui/material/Switch';

const ToggleSwitch = ({ enabled, onToggle }) => {
  return (
    <Switch
      checked={enabled}
      onChange={onToggle}
      color="primary"
    />
  );
};

export default ToggleSwitch;