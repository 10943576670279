import { useState, useEffect } from 'react';

function CountdownTimer({ onTimeout, interval = 60000 }) { // 1 minutes by default
  const [timeLeft, setTimeLeft] = useState(interval);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          onTimeout();
          return interval;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onTimeout, interval]);

  return (
    <div>
      Next Refresh In: {Math.floor(timeLeft / 60000)}:{(Math.floor(timeLeft / 1000) % 60).toString().padStart(2, '0')}
    </div>
  );
}

export default CountdownTimer;
