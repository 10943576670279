import React,{ useState, useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {clearAccessToken, setUserInfo} from '../store/reducers/userSlice';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Logo from '../components/logo';
// @mui
import { Container, Typography,CircularProgress,Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function withAccessTokenCheck(WrappedComponent){
  return (props) => {
    const accessToken = useSelector((state) => state.user.accessToken);
    const userInfo = useSelector((state) => state.user.userInfo);
    const location = useLocation();
    const dispatch = useDispatch();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
      if(currentPath !== '/auth' && accessToken){
        //always load userInfo again when the page 
        if(userInfo === null){
          axios.get(`${process.env.SUPPLIER_SYNC_API_URL}/syncAppAuth/userInfo`,{
            headers: {
              'Authorization': accessToken,
            },
          }).then(response =>{
            if (response.status === 200 && response.data && response.data.shopName) {
              setIsLoading(false);
              const shopName = response.data.shopName;
              const dszUserName = response.data.dszUserName;
              const invSyncSetting = response.data.invSyncSetting;
              const markDownOptions = response.data.markDownOptions;
              dispatch(setUserInfo({shopName,dszUserName,invSyncSetting, markDownOptions}));
              if(currentPath === '/auth'){
                navigate("/dashboard/app");
              }
            }
          })
          .catch(error => {
            if (error.response) {
              //if status is forbidden, clean the access token in store and navigate to auth page
              if(error.response.status === 403){
                dispatch(clearAccessToken());
                dispatch(setUserInfo(null));
                //consider to show error directly here, with loaded message;
                navigate("/auth");
              } else if(error.response.status === 401){
                dispatch(clearAccessToken());
                if (error.response.data && error.response.data.redirectUrl){
                  window.location.href = error.response.data.redirectUrl;
                }
              }
            }
          })
          // dispatch(setUserInfo(mockUserInfo));
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        if (currentPath !== '/auth'){
          navigate("/auth");
        }
      }
    },[isLoading,userInfo]);
    //if isLoading, render loading component
    if(isLoading){
      return (
      <>
        <Helmet>
            <title> App loading | DSZ Sync App </title>
        </Helmet>
        <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        <Container maxWidth="sm">
          <StyledContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
                <CircularProgress sx={{ marginBottom: 2,color: '#00BFFF' }} />
                <Typography variant="body1" sx={{ color: '#00BFFF', fontWeight: 'bold', letterSpacing: '2px', marginTop: '16px' }}>LOADING...</Typography>
            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
      </>)
    }
    return <WrappedComponent {...props} />;
  };
};

