import axios from 'axios';
import { handleUnauthorizedError } from '../utils/errorHandler';

export const updateSettings = async (accessToken,currentUserInfo, newSettings) => {
    try{
        const updateSettingUrl = `${process.env.SUPPLIER_SYNC_API_URL}/account/settings`
        const response = await axios.post(updateSettingUrl,newSettings,{
        headers: {
            'Authorization': accessToken
        }
        })
        if (response.status === 200 && response.data && response.data.validationResponseVO) {
            const newUserInfo = {...currentUserInfo, ...newSettings};
            return { success: true, data: newUserInfo };
        } 
        return { success: false, error: "Failed to update settings, please try again later." };
    } catch(error){
         //if 401, self redirect to login page
         handleUnauthorizedError(error);
         return { success: false, error: "Failed to update settings, please try again later.", originalError: error };
    }
}