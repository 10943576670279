import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Alert, Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { clearAccessToken,setUserInfo } from '../../../store/reducers/userSlice';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage ,setErrorMessage]= useState('');
  const accessToken = useSelector((state) => state.user.accessToken);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClick = async () => {
    try {
      const apiParams = {dszUserName: email, dszPassword: password };
      const response = await axios.post(`${process.env.SUPPLIER_SYNC_API_URL}/syncAppAuth/dszRegistration`, apiParams, 
      {
        headers: {
          'Authorization': accessToken,
        }
      } );

      if (response.status === 200 && response.data && response.data.shopName) {
        dispatch(setUserInfo({shopName: response.data.shopName, dszUserName: response.data.dszUserName,invSyncSetting: response.data.invSyncSetting}));
        navigate("/dashboard");
      } else {
        // Handle the error response from the API
      }
    } catch (error) {
      if(error.response){
        //switch error status
        const errorData =error.response.data ? error.response.data : {};
        switch(error.response.status){
          case 406:
            //add binding error message
            setErrorMessage(errorData.message);
            break;
          case 401:
            dispatch(clearAccessToken());
            if(errorData.validationResponseVO.redirectUrl){
               window.location.href = errorData.validationResponseVO.redirectUrl;
            }
            break;
          case 403:
            dispatch(clearAccessToken());
            dispatch(setUserInfo(null));
            navigate("/auth");
            //add authorization error message
            break;
          case 417:
            //add expectation failed error message
            setErrorMessage(errorData.message);
            break;
          default:
            //add default error message
            setErrorMessage("Something went wrong. Please try again later.");
            break;
      }
      // // Handle the network error
      // console.error('Network error:', error);
    }
  }};

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Dropshipzone Email address" value={email} onChange={handleChangeEmail} />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handleChangePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Save
      </LoadingButton>

      {errorMessage&&
      <Alert severity="error" sx={{ width: '100%', backgroundColor: '#ffebee',marginTop: '16px' }}>
      {errorMessage}
    </Alert>}
    </>
  );
}
