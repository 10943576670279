import { Helmet } from 'react-helmet-async';
import { CSVLink } from 'react-csv';
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import { Container, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import withAccessTokenCheck from '../hoc/withAccessTokenCheck';
import { setStatistics } from '../store/reducers/productMatchingSlice';
import { fetchStatistics, fetchJsonFile } from '../services/statisticsService';
import { comparePricesAndGenerateProductList, compareContentsAndGenerateProductList } from '../services/existingProductManageService';
import {extractHeaders} from '../utils/csvHelper';
// components
import Logo from '../components/logo';
import Loader from '../components/loader/Loader';

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));
  
  const ResultPanel = styled('div')(({ theme }) => ({
      backgroundColor: '#f8f9fa', // light gray background
      padding: theme.spacing(2),
      borderRadius: '8px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // subtle shadow
      marginTop: theme.spacing(2),
  }));
  

const ExistingProductManage = () => {
  const statisticInfo= useSelector((state) => state.productMatching.statistics);
  const accessToken = useSelector((state) => state.user.accessToken);
  const userInfo = useSelector((state) => state.user.userInfo);
  const markdownRate = userInfo?.markDownOptions?.defaultRate || 0;
  const dispatch = useDispatch();
  
  // State for handling loading and error messages
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [displayResults, setDisplayResults] = useState(false);
  const [conversionResults, setConversionResults] = useState({
    total: 0,
    priceUpdate: 0
  });

  const [productsForPriceUpdate, setProductsForPriceUpdate] = useState([]);
  const [priceUpdateCSVHeaders, setPriceUpdateCSVHeaders] = useState([]);

  const [productsForContentUpdate, setProductsForContentUpdate] = useState([]);
  const [contentUpdateCSVHeaders, setContentUpdateCSVHeaders] = useState([]);
  

  const fetchStatisticsData = async ()=>{
    const response = await fetchStatistics(accessToken);
    if (response.success) {
      //set the retrieval time to avoid repeated calls
      response.data.retrievalTime = new Date();
      dispatch(setStatistics(response.data));
    } else {
      // Handle the error response from the API
      setErrorMessage(response.error);
    }
    //stop loading either success or failed call; if call failed, display error message instead
    setIsLoading(false);
  }

  const handlePriceUpdate = async () => {
    if (markdownRate <=0 || markdownRate >=1){
        setErrorMessage("Invalid markdown rate detected. Please set up markdown rate in your user settings.");
        return; 
    }
    const response = await fetchJsonFile(statisticInfo.productMatchedResultJson,"Failed to fetch matched products. Please try again.");
    if (response.success) {
        const results = comparePricesAndGenerateProductList(response.data,markdownRate);
        setProductsForPriceUpdate(results);
        const priceUpdateHeaders = results.length > 0 
        ? extractHeaders(results[0]) 
        : [];
        setPriceUpdateCSVHeaders(priceUpdateHeaders);

        const contentUpdateResults = compareContentsAndGenerateProductList(response.data);
        setProductsForContentUpdate(contentUpdateResults);
        const contentUpdateHeaders = contentUpdateResults.length > 0  
        ? extractHeaders(contentUpdateResults[0]):[];
        setContentUpdateCSVHeaders(contentUpdateHeaders);
        //TODO: add full headers

        setConversionResults({
            total: response.data.length,
            priceUpdate: results.length,
            contentUpdate: contentUpdateResults.length
          });
        setDisplayResults(true);
    } else {
        // Handle the error response from the API
        setErrorMessage(response.error);
        setDisplayResults(false);
    }
};

  useEffect(()=>{
    const THRESHOLD_IN_MINUTES = 10;
    const MILLISECONDS_IN_A_MINUTE = 1000 * 60;

    if (statisticInfo.retrievalTime && statisticInfo.retrievalTime instanceof Date) {
        const now = new Date();
        const differenceInMilliseconds = now - statisticInfo.retrievalTime;
        const differenceInMinutes = differenceInMilliseconds / MILLISECONDS_IN_A_MINUTE;
        if (differenceInMinutes > THRESHOLD_IN_MINUTES) {         
            fetchStatisticsData();
        } else {
            setIsLoading(false);
        }
    } else {
        fetchStatisticsData();
    }  
  },[])

  const errorMessageLabel = (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb:2 }}>
        <ErrorIcon sx={{ marginRight: 1 }} />
        <Typography variant="h4" sx={{ color: 'warning.contrastText' }}>{errorMessage}</Typography>
      </Box>
    </Box>
  )

  // Render loading state or error messages if needed
  if (isLoading){
    return <Loader />
  }


  return (
    <>
      <Helmet>
        <title>Manage Existing Products</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <>
            {errorMessage && errorMessageLabel}
            <>
              <Typography variant="h4" gutterBottom>
                Existing Product Management
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', background: '#f4f6f8', padding: '10px', borderRadius: '4px' }}>
                When you click on "Generate Existing Products CSV",  two CSV files will be generated, one is for existing products that need price updates, the other is for existing products that requires content updates.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', background: '#f4f6f8', padding: '10px', borderRadius: '4px' }}>
                Markdown rate change or shopify product price change will trigger price update records.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', background: '#f4f6f8', padding: '10px', borderRadius: '4px' }}>
                For product content update: if you need to disable/enable the products, please set the "Status" value accordingly.
              </Typography>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handlePriceUpdate}>
                  Generate Existing Products CSVs
              </LoadingButton>
              {displayResults &&
               <ResultPanel>
               <Typography variant="h4">Conversion Results</Typography>
               <Typography variant="body1">Total Products: {conversionResults.total}</Typography>
               <Typography variant="body1">Products for Price Updates: {conversionResults.priceUpdate}</Typography>
               {conversionResults.priceUpdate > 0 && (
                 <CSVLink data={productsForPriceUpdate} headers={priceUpdateCSVHeaders} filename={"priceUpdates.csv"} enclosingCharacter={`"`}>Download Price Update CSV</CSVLink>
               )}
              <Typography variant="body1">Products for Price Updates: {conversionResults.contentUpdate}</Typography>
              {conversionResults.contentUpdate > 0 && (
                 <CSVLink data={productsForContentUpdate} headers={contentUpdateCSVHeaders} filename={"contentUpdates.csv"} enclosingCharacter={`"`}>Download Content Update CSV</CSVLink>
               )}
              </ResultPanel>             
              } 
              </>
            </>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
    );
};

export default withAccessTokenCheck(ExistingProductManage);
